.item_title {
  font-weight: bold;
  font-size: 13pt;
  text-align: justify;
  cursor: pointer;
}

@media (max-width: 800px) {
  .item_title {
    font-size: 12pt;
  }
}